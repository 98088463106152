// Flip, ScrollTrigger, Observer, ScrollToPlugin, Draggable, MotionPathPlugin, CustomEase,
// DrawSVGPlugin, ScrollSmoother, GSDevTools, InertiaPlugin, MorphSVGPlugin, MotionPathHelper,
// SplitText, CustomBounce, CustomWiggle,
import { gsap, ScrollTrigger, MorphSVGPlugin } from "gsap/all";

gsap.registerPlugin(ScrollTrigger, MorphSVGPlugin);
export default () => {
  //------------------------------------------------------//
  // Setup 🧦 GSAP and register 📜 ScrollTrigger
  // Register more plugins like so: gsap.registerPlugin(ScrollTrigger, splitText);
  // Go to https://greensock.com/docs/v3/Installation?checked=core,scrollTrigger,splitText#installer
  //------------------------------------------------------//
  // gsap.registerPlugin(Flip, ScrollTrigger, Observer, ScrollToPlugin, Draggable, MotionPathPlugin, CustomEase, DrawSVGPlugin, ScrollSmoother, GSDevTools, InertiaPlugin, MorphSVGPlugin, MotionPathHelper, SplitText, CustomBounce, CustomWiggle);

  ScrollTrigger.defaults({
    toggleActions: "restart pause resume pause",
    markers: window.location.hostname.endsWith(".local") ? true : false,
  });

  console.clear(); // Start with a clean console on refesh
  //------------------------------------------------------//
  // Animation ELEMENT
  //------------------------------------------------------//
  document.querySelectorAll(".ACF-kenmerken").forEach(function (container) {
    const q = gsap.utils.selector(container); // scope to current
    const move = 95;
    const duration = 0.5;
    const morph = gsap.utils.wrap(q(".morph"));
    const text = q(".text > *");
    const textWrap = gsap.utils.wrap(text);
    const bg = q("#bg-group > *");
    const bgWrap = gsap.utils.wrap(bg);
    //--------------------------------//
    // Setup
    //--------------------------------//
    gsap.set(bg, {
      xPercent: move,
    });
    gsap.set(bg[0], {
      xPercent: -(move / 2),
    });
    gsap.set(bg[1], {
      xPercent: move / 2,
    });

    // END Setup --------------//

    const tl = gsap.timeline({
      repeat: -1,
      defaults: {
        duration: duration,
        ease: "none",
      },
      scrollTrigger: {
        trigger: container,
        start: "top bottom",
        end: "bottom top",
        toggleActions: "play pause resume pause",
      },
    });
    tl.set(text[0], { autoAlpha: 1 });

    text.forEach((item, index) => {
      tl.to(q(".held"), {
        opacity: 0,
      });
      tl.to(bgWrap(index), { xPercent: `-=${move}` });
      tl.to(bgWrap(index + 1), { xPercent: `-=${move}` }, "<");
      tl.to(
        bgWrap(index + 2),
        { duration: duration / 2, xPercent: `-=${move / 2}` },
        `-=${duration / 2}`,
      );
      tl.to(textWrap(index), { duration: 0.3, autoAlpha: 0 }, "<");

      tl.from(
        q(".held")[index],
        {
          opacity: 0,
        },
        "<",
      );
      tl.to(textWrap(index + 1), { duration: 0.3, autoAlpha: 1 }, "<");
      tl.to(
        q("#text-kader"),
        { duration: 1, morphSVG: morph(index), ease: "elastic.out(1, 0.3)" },
        "<",
      ); // Morph the text bg
      tl.add(() => {}, "+=2"); // Add pasue
      tl.set(bgWrap(index), { xPercent: move }); // Reset offscreen bg when offscreen
    });
  });
  // END Animation ELEMENT -------------------------------------//
  document.querySelectorAll(".has-ornaments").forEach(function (container) {
    // Create ornaments container
    const ornaments = document.createElement("ul");
    ornaments.classList.add("ornaments");
    container.appendChild(ornaments);

    // Setup
    const amount = gsap.utils.random(2, 4);
    const containerWidth = container.offsetWidth;
    const containerHeight = container.offsetHeight;
    const items = [];

    for (let i = 0; i < amount; i++) {
      const item = document.createElement("li");
      const random = {
        class: gsap.utils.random(["diap", "one", "three", "diap"]),
        size: gsap.utils.random(["small", "medium", "large", "small"]),
      };
      ornaments.appendChild(item);
      items.push(item);
      item.classList.add(random.class);
      item.classList.add(random.size);
      const randomEdge = Math.floor(Math.random() * 2);
      const fromEdge = gsap.utils.random(-50, containerWidth * 0.1);
      const randomPositionH = Math.floor(Math.random() * containerHeight);
      const randomPositionW = Math.floor(Math.random() * containerWidth);

      // Select random edge
      switch (randomEdge) {
        case 0: // Left edge
          item.style.top = randomPositionH + "px";
          item.style.left = fromEdge + "px";
          break;
        case 1: // Right edge
          item.style.top = randomPositionH + "px";
          item.style.right = fromEdge + "px";
          break;
        case 2: // Top edge
          item.style.top = "0";
          item.style.left = randomPositionW + "px";
          break;
        case 3: // Bottom edge
          item.style.bottom = "0";
          item.style.left = randomPositionW + "px";
          break;
      }
    }

    //
    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: container, // What element triggers the scroll
        scrub: 0.5, // Add a small delay of scrolling and animation. `true` is direct
        start: "top bottom", // Can be top, center, bottom
      },
    });

    // console.warn(items);

    timeline.to(items, {
      // Get the max scroll height and get a random percentage from that value
      y: (index) =>
        -(container.offsetHeight / 2) *
        gsap.utils.wrap([1.2, 0.8, 0.4, 0.1], index),
      // // Disable default easing for a smoother scroll
      ease: "none",
    });
  });

  document.querySelectorAll(".ACF-fancy_text").forEach(function (container) {
    const q = gsap.utils.selector(container); // scope to current
    const move = 95;
    const duration = 0.5;
    const morph = gsap.utils.wrap(q(".morph"));
    const text = q(".text > *");
    const textWrap = gsap.utils.wrap(text);
    const bg = q("#bg-group > *");
    const bgWrap = gsap.utils.wrap(bg);
    //--------------------------------//
    // Setup
    //--------------------------------//
    gsap.set(bg, {
      xPercent: move,
    });
    gsap.set(bg[0], {
      xPercent: -(move / 2),
    });
    gsap.set(bg[1], {
      xPercent: move / 2,
    });

    // END Setup --------------//

    // const tl = gsap.timeline({
    //   repeat: -1,
    //   defaults: {
    //     duration: duration,
    //     ease: "none",
    //   },
    //   scrollTrigger: {
    //     trigger: container,
    //     start: "top bottom",
    //     end: "bottom top",
    //     toggleActions: "play pause resume pause",
    //   },
    // });
    // tl.set(text[0], { autoAlpha: 1 });

    // text.forEach((item, index) => {
    //   tl.to(q(".held"), {
    //     opacity: 0,
    //   });
    //   tl.to(bgWrap(index), { xPercent: `-=${move}` });
    //   tl.to(bgWrap(index + 1), { xPercent: `-=${move}` }, "<");
    //   tl.to(
    //     bgWrap(index + 2),
    //     { duration: duration / 2, xPercent: `-=${move / 2}` },
    //     `-=${duration / 2}`,
    //   );
    //   tl.to(
    //     textWrap(index),
    //     { duration: 0.3, autoAlpha: 0, xPercent: -50 },
    //     "<",
    //   );

    //   tl.from(
    //     q(".held")[index],
    //     {
    //       opacity: 0,
    //     },
    //     "<",
    //   );
    //   tl.to(textWrap(index + 1), { duration: 0.3, autoAlpha: 1 }, "<");
    //   tl.from(textWrap(index + 1), { duration: 0.3, xPercent: 50 }, "<");
    //   tl.to(
    //     q("#text-kader"),
    //     { duration: 1, morphSVG: morph(index), ease: "elastic.out(1, 0.3)" },
    //     "<",
    //   ); // Morph the text bg
    //   tl.add(() => {}, "+=2"); // Add pasue
    //   tl.set(bgWrap(index), { xPercent: move }); // Reset offscreen bg when offscreen
    // });
  });
  // END Animation ELEMENT -------------------------------------//
  // END Setup 🧦 GSAP -------------------------------------//
};
